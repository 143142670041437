html,
body {
  margin: 0;
}
// CSS for DataGrid ONCell Click Started
.cursor-pointer {
  cursor: pointer;
}

.react-datepicker__header {
  background-color: #f5f5f5 !important; /* Light background for header */
  border-bottom: 1px solid #ddd !important; /* Border at the bottom of the header */
  padding: 15px !important;
  border-radius: 8px 8px 0 0 !important; /* Rounded top corners */
}

.react-datepicker__current-month {
  font-size: 16px !important; /* Adjust font size for the month name */
  color: #333 !important; /* Dark color for text */
}

.react-datepicker__day {
  font-size: 14px !important; /* Adjust font size for days */
  color: #333 !important; /* Dark color for day numbers */
}

.react-datepicker__day--selected {
  background-color: #007bff !important; /* Background color for selected day */
  color: white !important; /* Text color for selected day */
}

.react-datepicker__day--hovered {
  background-color: #e9ecef !important; /* Background color on hover */
}

.react-datepicker{
  border:1px solid #dadada !important;
}

.swiper-pagination{
  position: static !important;
  margin-top:20px
}
.swiper-pagination-bullet {
  background-color: #FFCC00 !important;
  opacity: 1  !important;
  width: 16px  !important;
  height: 16px  !important;
  margin: 0 5px  !important;
  border-radius: 50%  !important;
}
.swiper-pagination-bullet-active {
  background-color: black !important;
}

.rdrCalendarWrapper{
  border: 1px solid #CFD2D6 ;
  border-radius: 10px ;
  font-size: 12px !important;
}
@media (max-width: 900px) {
  .rdrCalendarWrapper {
    font-size: 11px !important;
  }
  .propertyImage {
    width: 100% !important;
    height: 100% !important;

  }
}

@media (max-width: 600px) {
  .propertyImage {
    width: 100% !important;
    height: 150px !important;

  }
}
.rdrNextPrevButton , .rdrNextButton {
  background-color: #ffffff !important ;
  border: 1px solid #CFD2D6 !important;
  width:44px !important;
  height: 44px !important;
}
.rdrNextButton i{
  margin: auto !important;
}
.rdrMonth{
  background-color: #FFFFFF33 !important;
  margin: 8px !important;
  border-radius: 8px ;
}

.css-7bc43y-MuiList-root-MuiMultiSectionDigitalClockSection-root::after {
height: auto !important;
}
.rdrDay,.rdrDayToday{
  padding: 20px !important;
  height: 42px !important;
  font-weight: 400 !important;
  color: #0D1E32 !important;
}
.rdrSelected {
  border-radius: 30px !important;
  background-color: #FFCC00  !important;
}
.rdrDayEndPreview,.rdrDayStartPreview {
  border-radius: 500px !important;
  border: none !important;
}
.rdrDayNumber{
  border-radius: 5px !important;
}
.form-control {
  border-radius: 50px !important;
  height: 56px !important;
}
.css-17s82il-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
 background-color: transparent !important;
 border: '1px solid #0D1E32'
}
.css-17s82il-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
  opacity: 1 !important;
}
.rdrDayHovered .rdrDayNumber::after{
  border: none !important;
}
.css-1jqq78o-placeholder{
  margin-left: 18px !important;
}
